import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import AOS from 'aos'
import { Helmet } from "react-helmet"
import Layout from "../layouts"

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

class ServiceTemplate extends Component {
  
  componentDidMount() {
    AOS.init();
    window.onscroll = function() {
      myFunction();
    };
  }

  render() {
    const post = this.props.data.wordpressWpServices
    let house = '';

    let acfColor = '#F3A738';

    return (
      <Layout>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {post.title}</title>
          
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
        <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </Layout>
    )
  }
}

ServiceTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default ServiceTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpServices(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
